// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-founders-ricardo-js": () => import("./../../../src/pages/founders/ricardo.js" /* webpackChunkName: "component---src-pages-founders-ricardo-js" */),
  "component---src-pages-founders-tiago-js": () => import("./../../../src/pages/founders/tiago.js" /* webpackChunkName: "component---src-pages-founders-tiago-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projetos-avina-js": () => import("./../../../src/pages/projetos/avina.js" /* webpackChunkName: "component---src-pages-projetos-avina-js" */),
  "component---src-pages-projetos-lab-js": () => import("./../../../src/pages/projetos/lab.js" /* webpackChunkName: "component---src-pages-projetos-lab-js" */),
  "component---src-pages-projetos-monitoraea-js": () => import("./../../../src/pages/projetos/monitoraea.js" /* webpackChunkName: "component---src-pages-projetos-monitoraea-js" */),
  "component---src-pages-projetos-origens-js": () => import("./../../../src/pages/projetos/origens.js" /* webpackChunkName: "component---src-pages-projetos-origens-js" */),
  "component---src-pages-projetos-territorio-js": () => import("./../../../src/pages/projetos/territorio.js" /* webpackChunkName: "component---src-pages-projetos-territorio-js" */),
  "component---src-pages-projetos-vftlab-js": () => import("./../../../src/pages/projetos/vftlab.js" /* webpackChunkName: "component---src-pages-projetos-vftlab-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

